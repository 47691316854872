import React from "react";
import { graphql } from "gatsby";
import get from "lodash/get";
import { injectIntl } from "gatsby-plugin-intl";
import { Contentful_Article } from "graphql-types";
import { ContentTypeColour, ThemeContext, Type } from "../contexts/ThemeContext";
import Layout from "../layouts/layout";
import Article from "../components/article";
import contentfulImageHelper from "../utils/contentful-image-helper";

const ArticleEntryTemplate = ({ data }: { data: any }) => {
  const articleData: Contentful_Article = get(data, "contentful.article");
  return (
    <ThemeContext.Provider value={Type(articleData.contentType?.name ?? "")}>
      <Layout
        headSnippet={articleData.headSnippet}
        title={articleData.title}
        metaDescription={articleData.metaDescription}
        metaImageUrl={contentfulImageHelper(articleData.shareImage).getShareUrl()}
        hasHero={Type(articleData.contentType?.name ?? "") === ContentTypeColour.Podcasts}
      >
        <main>
          <Article article={articleData} />
        </main>
      </Layout>
    </ThemeContext.Provider>
  );
};

export default injectIntl(ArticleEntryTemplate);

export const articleQuery = graphql`
  query ArticleById($id: String!, $contentful_locale: String!, $preview: Boolean!) {
    contentful {
      article(id: $id, locale: $contentful_locale, preview: $preview) {
        ...articleFields
      }
    }
  }
`;
